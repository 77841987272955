import { Query } from 'react-apollo';
import styled from 'styled-components';
import qs from 'qs';
import { Helmet } from 'react-helmet-async';
import constants from 'constants/constants';
import newsesQuery from 'graphql/queries/newses.graphql';
import { metaDescription, metaKeywords, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Pagination from 'components/molecules/Pagination';
import Redirect from 'components/atoms/Redirect';
import NewsCard from './NewsCard';

const Title = styled.h1`
  font-weight: normal;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const NEWS_PER_PAGE = 30;

const NewsesPage = ({ location }) => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const rawPage = queryParams.page;

  if (typeof rawPage !== 'undefined' && (isNaN(+rawPage) || +rawPage <= 0)) {
    return <NotFoundPage />;
  }

  const page = +rawPage || 1;

  if (+rawPage === 1) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: qs.stringify({ ...queryParams, page: undefined }, { arrayFormat: 'brackets' }),
        }}
      />
    );
  }

  return (
    <Query
      query={newsesQuery}
      variables={{
        offset: (page - 1) * NEWS_PER_PAGE,
        limit: NEWS_PER_PAGE,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingPage />;
        }

        if (error) {
          return <ErrorLoadingPage />;
        }

        const totalPages = Math.ceil(
          data.newsesConnectionAggregated.aggregate.count / NEWS_PER_PAGE,
        );

        if (page > totalPages && totalPages !== 0) {
          return <NotFoundPage />;
        }

        return (
          <MainTemplate>
            <Helmet>
              <title>{`${title.newses}${page > 1 ? ` — страница ${page}` : ''}`}</title>
              <meta name="keywords" content={metaKeywords.newses} />
              <meta name="description" content={metaDescription.newses} />
              {page > 1 && (
                <link
                  rel="prev"
                  href={`${constants.BASE_URL}${location.pathname}${qs.stringify(
                    {
                      ...queryParams,
                      page: page - 1 === 1 ? undefined : page - 1,
                    },
                    { addQueryPrefix: true, arrayFormat: 'brackets' },
                  )}`}
                />
              )}
              {page !== totalPages && (
                <link
                  rel="next"
                  href={`${constants.BASE_URL}${location.pathname}${qs.stringify(
                    {
                      ...queryParams,
                      page: page + 1,
                    },
                    { addQueryPrefix: true, arrayFormat: 'brackets' },
                  )}`}
                />
              )}
            </Helmet>
            <Title>Новости</Title>
            {totalPages === 0 ? (
              <p>Нет новостей</p>
            ) : (
              <>
                {data.newsesConnection.edges.map((edge) => (
                  <NewsCard key={edge.node.id} news={edge.node} />
                ))}
                <StyledPagination total={totalPages} currentPage={page} />
              </>
            )}
          </MainTemplate>
        );
      }}
    </Query>
  );
};

export default NewsesPage;
